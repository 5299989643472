import React, {useState, useEffect, useCallback} from 'react'
import moment from 'moment-timezone';

import {Spinner} from '@material-tailwind/react'
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

import { encodedAddressPrefix, tippingAddress, ownerAddress, royalty, tip, contentLength, serviceFeeRate, padding, feeAddresses } from '../configs/constant';
import { Input } from '@material-tailwind/react'
import { formatAddress } from '../util/format-data'
import { registerPayment, getPaymentTx, getPaymentUtxos, getMintStatus, tokenLatest, registerToken } from '../util/api'
import { getAsciiSum } from '../util/format-data';
import { getFeeRate, bytesToHex, buf2hex, textToHex, hexToBytes, getMempoolUtxos, loopTilAddressReceivesMoney, waitSomeSeconds, addressReceivedMoneyInThisTx, pushBTCpmt, calculateFee, getData, isValidTaprootAddress} from '../util/inscribe-util';
import FeeRateCard from '../components/FeeRateCard';
import mintImg from '../assets/imgs/mintimg.png';
import logonameImg from '../assets/imgs/logoname.png'; 
import { getAdminData, mintCount, mintRecommend, mintCancel, mintRegister, mintTotal } from '../util/api';
import { fetchImageAsHex } from '../util/inscribe-util';
import { BitcoinNetworkType, signMessage, signTransaction, sendBtcTransaction } from 'sats-connect';

import { useNavigate, useLocation } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Tooltip,
  Slider,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from "@material-tailwind/react";

import { useWallet, useWallets } from '@wallet-standard/react';

export default function Mint() {
  const { wallets } = useWallets();

  const SatsConnectNamespace = 'sats-connect:';

  const isSatsConnectCompatibleWallet = (wallet) => {
      return SatsConnectNamespace in wallet.features;
  }

  const wallet = useSelector(state => state.wallet);

  const navigate = useNavigate();

  const { Address, Script, Signer, Tap, Tx } = window.tapscript;
  const feeRateTabs = ["Slow", "Normal", "Fast", "Custom"];

  const [feeRateMode, setFeeRateMode] = useState("Normal");
  const [feerate, setFeerate] = useState(0);
  const [feeRates, setFeeRates] = useState({});
  const [feeValues, setFeeValues] = useState({
    "inscriptionFee": 0,
    "networkFee": 0,
    "serviceFee": 0,
    "royaltyFee": 0,
    "totalFee": 0
  });
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState('');
  const [amount, setAmount] = useState(1);
  const [maximum, setMaximum] = useState(10000);
  const [sum, setSum] = useState(0);
  const [deployLimit, setDeployLimit] = useState(21000);
  const [deployCount, setDeployCount] = useState(0);
  const [address, setAddress] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [key, setKey] = useState('');
  const [customFee, setCustomFee] = useState(0);
  const [sliderValue, setSliderValue] = useState(2);
  const [show, setShow] = useState(false);
  const [inscriptionStatus, setInscriptionStatus] = useState(false);
  const [showMintForm, setShowMintForm] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState(0.7);
  const [paymentLimit, setPaymentLimit] = useState(0.7);
  const [deductPayment, setDeductPayment] = useState(0.1);
  const [timerText, setTimerText] = useState("00:00:00");
  const [referencedate, setReferencedate] = useState("2024-07-03");
  const [mintFlag, setMintFlag] = useState(true);
  const [referencetime, setReferencetime] = useState(0);

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      try {
        if (feeRateMode == "Custom") {
          setFeerate(customFee);
          // setFeeValues(calculateFee(customFee, amount, paymentAmount));
        }
        else
        {
          let response = await getFeeRate();
          setFeeRates(response);
          setFeerate(response[feeRateMode]);
          // setFeeValues(calculateFee(response[feeRateMode], amount, paymentAmount));
          if (customFee == 0) 
          {
            setCustomFee(response["Fast"] + 1);
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 10 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [feeRateMode, amount, customFee])

  useEffect(() => {
    let intervalId;

    const updateTimer = async () => {
      try {
        const gmtMinusFourTime = moment.tz("Etc/GMT+4").format('YYYY-MM-DD HH:mm:ss');

        // const currentDate = new Date(
        //   new Date().toLocaleString('en-US', { timeZone: 'Etc/GMT+4' })
        // );

        const currentDate = new Date(gmtMinusFourTime);
        const referenceDate = new Date(moment.tz(referencedate, "Etc/GMT+4").format('YYYY-MM-DD HH:mm:ss'));

        if (currentDate < referenceDate) setMintFlag(false);

        let amount = Math.floor((currentDate - referenceDate) / 1000);

        let day = Math.floor(amount / 60 / 60 /24);
        amount = (day + 1) * 60 * 60 *24 - amount;
        let seconds = amount % 60;
        let temp = Math.floor(amount / 60);
        let minutes = temp % 60;
        temp = Math.floor(temp / 60);
        let hours = temp % 24;

        setPaymentAmount(formatAmount(paymentLimit - deductPayment * day));
        setTimerText(formatTime(hours) + ":" + formatTime(minutes) + ":" + formatTime(seconds));
      }
      catch (e) {
        console.log(e);
      }
    }
    updateTimer();
    intervalId = setInterval(updateTimer, 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [referencedate, referencetime])

  useEffect(() => {
    const updateCounts = async() => {
      try {
        await handleUpdateCounts();
      }
      catch(e) {
        console.log(e);
      }
    }
    updateCounts();
  }, [])
  
  useEffect(() => {
    let intervalId;
    const handleAdminData = async() => {
      try {
        let result = await getAdminData();
        setReferencedate(handleReferencedate(result.start));
        setReferencetime(parseInt(result.start.split('-')[2]));
      }
      catch(e) {
        console.log(e);
      }
    }

    handleAdminData();
    intervalId = setInterval(handleAdminData, 100000);
    return () => {
      clearInterval(intervalId);
    }
  }, [])

  useEffect(() => {
    let value = (sliderValue / 100) * (500 - feeRates["Normal"]) + feeRates["Normal"];
    setCustomFee(Math.floor(value));
  }, [sliderValue])

  useEffect(() => { 
    setAddress(wallet.nostrOrdinalsAddress);
  }, [wallet.nostrOrdinalsAddress]);

  const handleReferencedate = (start) => {
    let startMonth = start.split('-')[0];
    let startDate = start.split('-')[1];
    let startTime = start.split('-')[2];

    if (parseInt(startMonth) <= 9 ) startMonth = '0' + startMonth;
    if (parseInt(startDate) <= 9 ) startDate = '0' + startDate;
    if (parseInt(startTime) <= 9 ) startTime = '0' + startTime;
    return "2024" + "-" + startMonth + "-" + startDate + " " + startTime + ":00:00";
  }

  const formatTime = (dateStr) => {
    if (dateStr < 10) return '0' + dateStr; else return '' + dateStr;
  }

  const formatAmount = (amount) => {
    if (amount < 0) return 0;
    else return Math.round(amount * 10) / 10;
  }

  const handleUpdateCounts = async () => {
    let results = await mintTotal();
    setDeployCount(results.data);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const debouncedSearch = useCallback(debounce((value) => {
    setPage(1);
    updateTokens(value, 1)
  }, 300), []); 

  const handleSearch = (value) => {
    setKey(value);
    debouncedSearch(value);
  };

  const getPaymentAmount = (value) => {
    const gmtMinusFourTime = moment.tz("Etc/GMT+4").format('YYYY-MM-DD HH:mm:ss');

    // const gmtMinusFourTime = new Date(
    //   new Date().toLocaleString('en-US', { timeZone: 'Etc/GMT+4' })
    // );

    const currentDate = new Date(gmtMinusFourTime);
    const referenceDate = new Date(moment.tz(referencedate, "Etc/GMT+4").format('YYYY-MM-DD HH:mm:ss'));

    let amount = Math.floor((currentDate - referenceDate ) / 1000);
    let day = Math.floor(amount / 60 / 60 /24);
    amount = (day + 1) * 60 * 60 *24 - amount;
    let seconds = amount % 60;
    let temp = Math.floor(amount / 60);
    let minutes = temp % 60;
    temp = Math.floor(temp / 60);
    let hours = temp % 24;

    return formatAmount(paymentLimit - deductPayment * day) * Math.pow(10, 8);
  }

  const handleMintForm = async (value) => {
    setLoading(true);
    let adminData = await getAdminData();
    if (adminData.status == 0) {
      toast.error("Mint Paused!");
    }
    else {
      setShowMintForm(true);
    }
    setLoading(false);
  }

  const handleMint = async () => {
    setLoading(true);
    setShow(true);
    setInscriptionStatus(false);   

    // const currentDate = new Date();
    // const referenceDate = new Date(referencedate);

    // if (currentDate < referenceDate) {
    //   toast.error("Please wait!");
    //   return;
    // }

    let adminData = await getAdminData();

    setReferencedate(handleReferencedate(adminData.start));

    let mintResult = await mintCount(wallet.nostrOrdinalsAddress);
    let totalMintCount = await mintTotal();
    
    setShowMintForm(false);

    if (wallet.nostrOrdinalsAddress == "")
    {
      toast.error("Please connect wallet first!");
      setLoading(false);
      setShow(false);
      return;
    }

    if (adminData.status == 0) {
      toast.error("Mint Paused!");
      setLoading(false);
      setShow(false);
      return;
    }

    if (amount != parseInt(amount)) {
      toast.error("Please insert valid amount!");
      setLoading(false);
      setShow(false);
      return;
    }

    if ( adminData.count < mintResult.data + amount) {
      toast.error(`Maximum Inscriptions Exceeded!`);
      setLoading(false);
      setShow(false);
      return;
    }

    if (amount > deployLimit - totalMintCount.data) {
      toast.error("Amount can not maximum amount!");
      setLoading(false);
      setShow(false);
      return;
    }

    if (address == "") {
      toast.error("Please insert wallet address!");
      setLoading(false);
      setShow(false);
      return;
    }

    if (!isValidTaprootAddress(address)) {
      toast.error("Please insert valid taproot address!");
      setLoading(false);
      setShow(false);
      return;
    }

    let fundingAddress = '';

    fundingAddress = adminData.address;

    let isSuccess = true;

    let inscriptionFee = 0;
    let fundingAmount = getPaymentAmount(handleReferencedate(adminData.start), parseInt(adminData.start.split('-')[2]));

    console.log("---------fundingAmount------------", fundingAmount);

    if (!typeof window) return
    if (!window.tapscript) return

    let cryptoUtils = window.cryptoUtils;
    const KeyPair = cryptoUtils.KeyPair;

    let privkey = bytesToHex(cryptoUtils.Noble.utils.randomPrivateKey());

    let seckey = new KeyPair(privkey);
    let pubkey = seckey.pub.rawX;

    const ec = new TextEncoder();

    const init_script = [
      pubkey,
      'OP_CHECKSIG'
    ];
    
    const init_script_backup = [
        '0x' + buf2hex(pubkey.buffer),
        'OP_CHECKSIG'
    ];

    let init_leaf = await Tap.tree.getLeaf(Script.encode(init_script));
    let [init_tapkey, init_cblock] = await Tap.getPubKey(pubkey, {target: init_leaf});

    const test_redeemtx = Tx.create({
      vin  : [{
          txid: 'a99d1112bcb35845fd44e703ef2c611f0360dd2bb28927625dbc13eab58cd968',
          vout: 0,
          prevout: {
              value: 10000,
              scriptPubKey: [ 'OP_1', init_tapkey ]
          },
      }],
      vout : [{
          value: 8000,
          scriptPubKey: [ 'OP_1', init_tapkey ]
      }],
    });
    
    const test_sig = await Signer.taproot.sign(seckey.raw, test_redeemtx, 0, {extension: init_leaf});
    test_redeemtx.vin[0].witness = [ test_sig.hex, init_script, init_cblock ];
    const isValid = await Signer.taproot.verify(test_redeemtx, 0, { pubkey });

    if(!isValid)
    {
      alert('Generated keys could not be validated. Please reload the app.');
      return;
    }

    let recommendResult = await mintRecommend(amount, address, privkey, feerate);

    let files = [];

    //let mimetype = "text/plain;charset=utf-8";
    let mimetype = "image/webp";
    let salts = "";
    let ids = [];

    for(let temp of recommendResult.data)
    {

      const hexText = await fetchImageAsHex("https://eyes4384.com/images/" + temp.number + ".webp");
      // const hexText = "hello" + temp.number;
      console.log("--------------", hexText);
      ids.push(temp.id);

      files.push({
        text: JSON.stringify(hexText),
        name: hexText,
        hex: hexText,
        mimetype: mimetype,
        sha256: ''
      });
    }

    let inscriptions = [];
    let inscriptionAddressList = [];

    let recipientList = [];
    recipientList.push({
      address: fundingAddress,
      amountSats: BigInt(fundingAmount),
    })

    let totalInscriptionFee = 0;

    for (let i = 0; i < files.length; i++) {

      const hex = files[i].hex;
      const data = hexToBytes(hex);
      const mimetype = ec.encode(files[i].mimetype);

      const script = [
          pubkey,
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          ec.encode('ord'),
          '01',
          mimetype,
          'OP_0',
          data,
          'OP_ENDIF'
      ];

      const script_backup = [
          '0x' + buf2hex(pubkey.buffer),
          'OP_CHECKSIG',
          'OP_0',
          'OP_IF',
          '0x' + buf2hex(ec.encode('ord')),
          '01',
          '0x' + buf2hex(mimetype),
          'OP_0',
          '0x' + buf2hex(data),
          'OP_ENDIF'
      ];

      const leaf = await Tap.tree.getLeaf(Script.encode(script));
      const [tapkey, cblock] = await Tap.getPubKey(pubkey, { target: leaf });

      let inscriptionAddress = Address.p2tr.encode(tapkey, encodedAddressPrefix);

      let prefix = 160;

      let txsize = prefix + Math.floor(data.length / 4);

      inscriptionAddressList.push(inscriptionAddress);

      inscriptionFee = getInscriptionFee(data.length);
      console.log(`----i: ${i} -------InscriptionFee: ${inscriptionFee}`)

      totalInscriptionFee += inscriptionFee;

      inscriptions.push(
        {
          leaf: leaf,
          tapkey: tapkey,
          cblock: cblock,
          inscriptionAddress: inscriptionAddress,
          txsize: txsize,
          fee: inscriptionFee - padding,
          script: script_backup,
          script_orig: script
        }
      );

      recipientList.push ({
        address: inscriptionAddress,
        amountSats: BigInt(inscriptionFee),
      })
    }
    
    let _fundingAddress = Address.p2tr.encode(init_tapkey, encodedAddressPrefix);

    totalInscriptionFee += royalty * amount;

    let addressList = [];
    let totalFee = calculateFee(1, amount + 1, feerate) + totalInscriptionFee;
    addressList.push(_fundingAddress);

    const paymentUtxos = await getPaymentUtxos(wallet.nostrPaymentAddress, addressList, totalFee, fundingAddress, fundingAmount, wallet.paymentPublicKey, feerate, 1, wallet.domain)

    if (paymentUtxos.status == "fail") {
      alert("Insufficient balance.");
      setLoading(false);
      setShow(false);
      isSuccess = false;
      await mintCancel(ids);
      return;
    }

    try{
      if (wallet.domain == "tapwallet") {
        const signedPsbt = await window.tapwallet.signPsbt(paymentUtxos.psbt);
        const txid = await window.tapwallet.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "unisat") {
        const signedPsbt = await window.unisat.signPsbt(paymentUtxos.psbt);
        const txid = await window.unisat.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "okxwallet") {
        const signedPsbt = await window.okxwallet.bitcoin.signPsbt(paymentUtxos.psbt);
        const txid = await window.okxwallet.bitcoin.pushPsbt(signedPsbt);
      }
      if (wallet.domain == "xverseWallet") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }
          await signTransaction({
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
                console.log('response: ', response);
            },
            onCancel: async () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                await mintCancel(ids);
                setShow(false);
            },
          });
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          await mintCancel(ids);
          return;
        }
      }
      if (wallet.domain == "magiceden") {
        let res = paymentUtxos;
        if (res.status == "success") {
          let signIndexes = [];
          for(let i=0;i<res.count; i++){
            signIndexes.push(i);
          }

          let magicedenWallets = wallets.filter(isSatsConnectCompatibleWallet);

          await signTransaction({
            getProvider: async () =>
              magicedenWallets[0].features['sats-connect:'].provider,
            payload: {
                network: {
                    type: BitcoinNetworkType.Mainnet,
                },
                psbtBase64: res.psbt,
                broadcast: true,
                message: "tip the author! Don't worry this will not be broadcasted.",
                inputsToSign: [
                    {
                        address: wallet.nostrPaymentAddress,
                        signingIndexes: signIndexes,
                    },
                ],
            },
            onFinish: async (response) => {
            },
            onCancel: async () => {
                alert('Request canceled');
                setLoading(false);
                isSuccess = false;
                setShow(false);
                await mintCancel(ids);
            },
          });
  
        }
        else {
          alert("Insufficient balance.");
          setLoading(false);
          setShow(false);
          isSuccess = false;
          await mintCancel(ids);
          return;
        }
      }
    }
    catch(e)
    {
      console.log(e);
      alert("Payment rejected by user. Try again.");
      isSuccess = false;
      setLoading(false);
      setShow(false);
      await mintCancel(ids);
      return;
    }
    
    if (isSuccess) {
      let transactionData;
      while(true)
      {
        transactionData = await getMempoolUtxos(_fundingAddress);
        if (transactionData.length >= 1){
          break;
        }
        await waitSomeSeconds(2);
      }

      let txinfo = await addressReceivedMoneyInThisTx(_fundingAddress);

      let txid = txinfo[0];
      let vout = txinfo[1];
      let amt = txinfo[2];

      let outputs = [];

      let transaction = [];
      transaction.push({txsize : 60, vout : vout, script: init_script_backup, output : {value: amt, scriptPubKey: [ 'OP_1', init_tapkey ]}});

      for (let i = 0; i < inscriptions.length; i++) {

          outputs.push(
              {
                  value: padding + inscriptions[i].fee,
                  scriptPubKey: [ 'OP_1', inscriptions[i].tapkey ]
              }
          );

          transaction.push({txsize : inscriptions[i].txsize, vout : i, script: inscriptions[i].script, output : outputs[outputs.length - 1]});
      }

      let rnd = Math.floor(Math.random() * 5);
      outputs.push(
        {
            value: royalty * amount,
            scriptPubKey: [ 'OP_1', Address.p2tr.decode(feeAddresses[rnd], encodedAddressPrefix).hex ]
        }
      );

      const init_redeemtx = Tx.create({
        vin  : [{
            txid: txid,
            vout: vout,
            prevout: {
                value: amt,
                scriptPubKey: [ 'OP_1', init_tapkey ]
            },
        }],
        vout : outputs
      })

      const init_sig = await Signer.taproot.sign(seckey.raw, init_redeemtx, 0, {extension: init_leaf});
      init_redeemtx.vin[0].witness = [ init_sig.hex, init_script, init_cblock ];

      let rawtx = Tx.encode(init_redeemtx).hex;
      let _txid = await pushBTCpmt(rawtx);

      for (let i = 0; i < inscriptions.length; i++) {
        while(true)
        {
          transactionData = await getMempoolUtxos(inscriptions[i].inscriptionAddress);
          if (transactionData.length >= 1){
            break;
          }
          await waitSomeSeconds(2);
        }
        await inscribe(inscriptions[i], i, transactionData[0].txid, inscriptions[i].fee + padding, seckey, ids[i], privkey);
      }
    }
    else {
      await mintCancel(ids);
    }

    await handleUpdateCounts();

    setLoading(false);
    setInscriptionStatus(true);
  }

  const inscribe = async(inscription, vout, txid2, amt2, seckey, registerId, privkey) => {
    let _toAddress;
    let _script;
    let toAddress = address;
    if(toAddress.startsWith('tb1q') || toAddress.startsWith('bc1q'))
    {
        _toAddress = Address.p2wpkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_0', _toAddress ];
        console.log('using p2wpkh', _script);
    }
    else if(toAddress.startsWith('1') || toAddress.startsWith('m') || toAddress.startsWith('n'))
    {
        _toAddress = Address.p2pkh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2pkh.scriptPubKey(_toAddress);
        console.log('using p2pkh', _script);
    }
    else if(toAddress.startsWith('3') || toAddress.startsWith('2'))
    {
        _toAddress = Address.p2sh.decode(toAddress, encodedAddressPrefix).hex;
        _script = Address.p2sh.scriptPubKey(_toAddress);
        console.log('using p2sh', _script);
    }
    else
    {
        _toAddress = Address.p2tr.decode(toAddress, encodedAddressPrefix).hex;
        _script = [ 'OP_1', _toAddress ];
        console.log('using p2tr', _script);
    }

    const redeemtx = Tx.create({
        vin  : [{
            txid: txid2,
            vout: vout,
            prevout: {
                value: amt2,
                scriptPubKey: [ 'OP_1', inscription.tapkey ]
            },
        }],
        vout : [{
            value: padding,
            scriptPubKey: _script
        }],
    });

    const sig = await Signer.taproot.sign(seckey.raw, redeemtx, 0, {extension: inscription.leaf});
    redeemtx.vin[0].witness = [ sig.hex, inscription.script_orig, inscription.cblock ];

    console.dir(redeemtx, {depth: null});

    let rawtx2 = Tx.encode(redeemtx).hex;
    let _txid2;

    _txid2 = await pushBTCpmt( rawtx2 );
    await sleep(1000);

    if(_txid2.includes('descendant'))
    {
        // include_mempool = false;
        // inscribe(inscription, vout, txid2, amt2, seckey);
        return;
    }

    try {
      JSON.parse(_txid2);
    } catch (e) {
      console.log(_txid2);
      /// Save inscription data
      ///
      let body = {
        id : registerId,
        number : registerId - 1,
        address : address,
        inscriptionId : _txid2 + "i0",
        privateKey : privkey
      }
      await mintRegister(body);
    }
    
  }

  const handleCopy = (value, mode) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        if (mode == 1)
          toast.success("Name copied!");
        else
          toast.success("Address copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const addAmount = () => {
    let amountInt = parseInt(amount);
    setAmount(amountInt + 1);
  }

  const deductAmount = () => {
    let amountInt = parseInt(amount);
    if (amountInt > 1) setAmount(amountInt - 1);
  }

  const getInscriptionFee = (length) => {
    let prefix = 160;
    let txsize = prefix + Math.floor(length / 4);

    return Math.floor(feerate * txsize * 1.1) + padding;
  }

  const calculateFee = (vins, vouts, recommendedFeeRate, includeChangeOutput = 0 ) => {
    if (typeof recommendedFeeRate !== "number" || recommendedFeeRate <= 0)
      throw new Error("Invalid fee rate.");
  
    const baseTxSize = 10;
    const inSize = 57.5;
    const outSize = 43;
    const txSize = baseTxSize + vins * inSize + vouts * outSize + includeChangeOutput * outSize;
    const fee = Math.round(txSize * recommendedFeeRate);
    return fee;
  }

  const handleDisclaimer = () => {
    navigate('/disclaimer');
  }

  return (
    <div className="text-white w-full  md:px-7 px-3 flex flex-col items-center min-h-[600px] z-[9999]">
      <div className="text-white max-w-[650px] flex flex-col items-center">
      <img src={logonameImg} />
      <a href="https://twitter.com/mrblack4384" target="_blank">
        <img src="https://mscribe-webapp.s3.amazonaws.com/twitter.png" alt="Twitter" className="cursor-pointer w-[24px]" />
      </a>
      <img src={mintImg} className="max-w-[380px] border-solid border-red-900 border-[3px] mt-7 w-full rounded-md"/>
      {
        mintFlag ?
        <>
          <div className="flex flex-row mt-5 gap-6">
            <div className="flex flex-col gap-2 items-center">
              <div className="flex flex-row gap-2 items-center border-red-900 border-solid border-[2px] py-2 px-4 rounded-md">
                <div className="font-bold cursor-pointer" onClick={() => {deductAmount()}}>-</div>
                <div className="w-[50px]"><input type="number" className="w-full bg-transparent text-center" value={amount} onChange = {(e) => {setAmount(e.target.value)}}/></div>
                <div className="font-bold cursor-pointer" onClick={() => {addAmount()}}>+</div>
              </div>
              <div className="text-[9px] font-bold">{deployCount}/{deployLimit}</div>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <div className="font-bold border-[2px] border-red-900 border-solid py-2 px-4 cursor-pointer rounded-md" onClick={() => {handleMintForm()}}>MINT NOW</div>
              <div className="text-[9px] font-bold">{paymentAmount} BTC (EXCLUDING GAS)</div>
            </div>
          </div>
          <div className="mt-5">
            <span className="font-bold text-red-900 mr-1">{timerText}</span>
            <span className="font-bold">UNTIL {deductPayment} BTC REDUCTION</span>
          </div>
        </>
        :
        <div className="text-red-900 font-bold text-[40px] mt-8">
          COMING SOON
        </div>
      }
      </div>
      <div className="flex flex-row gap-6">
        <a href='/disclaimer' target="_blank" className="text-red-900 text-[18px] font-bold hover:underline cursor-pointer text-center sm:mt-[60px] mt-[40px] mb-3">DISCLAIMER</a>
        <a href='/index' target="_blank" className="text-red-900 text-[18px] font-bold hover:underline cursor-pointer text-center sm:mt-[60px] mt-[40px] mb-3">ARTWORK VIEWER</a>
      </div>
      <Dialog
        open={showMintForm}
        size={"xs"}
      >
        <DialogHeader>
          <div className="flex flex-row justify-center items-center w-full">
            <img src={mintImg} className="w-[48px] h-[48px]"/>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-3 w-full min-h-[160px]">
            <div>
              <Input type="text" color="black" label = "Inscription Receiver Address" className="w-full min-w-[200px]" value={address} onChange = {(e) => {setAddress(e.target.value)}}/>
            </div>
            <div className="text-black">
              Select the network fee you want to pay:
            </div>
            <div className="grid grid-cols-3 gap-3">
              <FeeRateCard header={feeRateTabs[1]} rate={feeRates[feeRateTabs[1]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[1])}}/>
              <FeeRateCard header={feeRateTabs[2]} rate={feeRates[feeRateTabs[2]]} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[2])}}/>
              <FeeRateCard header={feeRateTabs[3]} rate={customFee} active={feeRateMode} onClick={() => {setFeeRateMode(feeRateTabs[3])}}/>
            </div>
            {
              feeRateMode == "Custom" ? 
              <div>
                <Slider color="blue" value = {sliderValue} onChange = {(e) => setSliderValue(e.target.value)}/>
              </div>
              : 
              <></>
            }
            {/* <div className="m-auto md:w-[400px] w-[300px]">
              <div className="grid grid-cols-2 mt-1 text-[20px]">
                <div className="font-bold text-black">Total Cost</div>
                <div className="text-right font-bold text-blue-600">{feeValues["totalFee"] / Math.pow(10, 8)} BTC</div>
              </div> 
            </div> */}
            <div className="flex flex-row w-full justify-center gap-4">
              <button className="border-[2px] text-black font-bold border-solid border-red-900 rounded-md py-2 min-w-[140px]" onClick={handleMint}>Mint</button>
              <button className="border-[2px] text-black font-bold border-solid border-red-900 rounded-md py-2 min-w-[140px]" onClick={() => {setShowMintForm(false)}}>Cancel</button>
            </div>
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={show}
        size={"xs"}
      >
        <DialogHeader>
          {
            inscriptionStatus ? 
              <div className="flex flex-row w-full justify-center mt-6 text-[32px] font-bold text-green-600">
                Inscription Success
              </div>
              :
              <div className="flex flex-row w-full justify-center mt-6 text-[32px] font-bold text-primary">
                Inscribing Now
              </div>
          }
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col gap-3 w-full min-h-[160px]">
            {
              inscriptionStatus ? 
              <div className="flex flex-col items-center gap-8">
                <div className="flex flex-row justify-center w-full text-[20px] px-6 font-semibold text-[#616161] text-center">
                  Thank you for participating. Together we are changing the digital landscape.
                </div>
                <div className="w-[160px]">
                  <Button
                    onClick={() => {
                      setShow(false);
                      setInscriptionStatus(false);
                    }}
                    fullWidth
                  >Close</Button>
                </div>
              </div>
              :
              <div className="flex flex-col items-center gap-5">
                <div className="flex flex-row justify-center w-full font-semibold text-[#616161] text-[20px] px-6 text-center">
                  Don´t close this window before the transaction is complete.
                </div>
                <Spinner className="h-12 w-12" />
              </div>
            }
          </div>
        </DialogBody>
      </Dialog>
      <Toaster 
        position="top-right"
      />
    </div>
  )
}
